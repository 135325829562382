// @ts-nocheck
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Prompt, withRouter } from "react-router";
import { uuid } from "uuidv4";
import Layout from "../../components/Layout/Layout";
import Modal from "../../components/Modal/Modal";
import GLOBAL from "../GLOBAL/GLOBAL";
import classes from "./ConversationCreator.module.css";
import ConversationStructure from "./ConversationStructure";
import { FontAwesomeIcon as F } from "@fortawesome/react-fontawesome";

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";

function ConversationBuilder(props) {
  const [conversationName, setConversationName] = useState("");
  const [conversationID, setConversationID] = useState("");
  const [vertical, setVertical] = useState("");
  const [conversationSchema, setConversationSchema] = useState({});
  const [loadingStructure, setLoadingStructure] = useState(true);
  const [showEditResponseModal, setShowEditResponseModal] = useState(false);
  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
  const [isUpToDate, setIsUpToDate] = useState(true);
  const [
    showAddQuestionResponsePairModal,
    setShowAddQuestionResponsePairModal,
  ] = useState(false);
  const [responseEdit, setResponseEdit] = useState("");
  const [questionEdit, setQuestionEdit] = useState("");
  const [responseIDEdit, setResponseIDEdit] = useState("");
  const [questionIDEdit, setQuestionIDEdit] = useState("");
  const [schemaIDToAddPair, setSchemaIDToAddPair] = useState("");
  const [notifications, setNotifications] = useState({});
  const [isSavingResponse, setIsSavingResponse] = useState(false);
  const [variableToSave, setVariableToSave] = useState("");
  const [searchingForSchemaRecords, setSearchingForSchemaRecords] = useState(
    {}
  );
  const [schemaRecords, setSchemaRecords] = useState([]);

  // setSchemaIDToDeletePair
  // setShowDeleteQuestionResponsePairModal

  const [schemaIDToDeletePair, setSchemaIDToDeletePair] = useState("");
  const [
    showDeleteQuestionResponsePairModal,
    setShowDeleteQuestionResponsePairModal,
  ] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      return console.log("not authenticated");
    }
    let config = {
      headers: {
        "x-auth-token": token,
      },
    };

    Axios.get(
      `${GLOBAL.domainConversation}/api/get-single-conversation/${props.match.params.id}`,
      config
    ).then((res) => {
      console.log(res.data);
      setConversationName(res.data.name);
      setConversationID(res.data._id);
      setVertical(res.data.vertical);
      setLoadingStructure(false);
      if (!res.data.ConversationSchema) {
        setConversationSchema({
          question: "",
          id: res.data._id,
          responseOptions: [],
        });
      } else {
        setConversationSchema(res.data.ConversationSchema);
      }
    });
  }, []);

  useEffect(() => {
    // setNotifications()
    if (notifications.hasOwnProperty("name")) {
      setTimeout(() => {
        setNotifications({});
      }, 9000);
    }
  }, [notifications]);
  const loader = (
    <>
      <div style={{ fontSize: 50, lineHeight: 1.1, marginTop: 30 }}>
        <Skeleton count={1} />
      </div>
      <div style={{ fontSize: 350, lineHeight: 1.1, marginTop: 30 }}>
        <Skeleton count={1} />
      </div>
    </>
  );

  const editResponse = (id, response, responseToSave) => {
    console.log(id, response);
    setShowEditResponseModal(true);
    setResponseEdit(response);
    setResponseIDEdit(id);
    if (responseToSave) {
      setIsSavingResponse(true);
      setVariableToSave(responseToSave);
    }
  };
  const editQuestion = (id, question) => {
    console.log(id, question);
    setShowEditQuestionModal(true);
    setQuestionEdit(question);
    setQuestionIDEdit(id);
    setSearchingForSchemaRecords(true);
    Axios.get(`${GLOBAL.messageFormatter}/api/getAllSchemas`)
      .then((response) => {
        setSchemaRecords(response.data);
        setSearchingForSchemaRecords(false);
      })
      .catch((err) => {
        console.log(err.response);
        setSearchingForSchemaRecords(false);
      });
  };

  const updateResponse = (isResponse, isAdding) => {
    console.log(responseIDEdit);
    console.log(responseEdit);
    const idToEdit = isResponse ? responseIDEdit : questionIDEdit;
    let newEdit = isResponse ? responseEdit : questionEdit;

    newEdit = newEdit.replace(/"/g, '\\"');

    const schemaCopy = {
      ...conversationSchema,
    };
    const PATHCOLLECTOR = [];
    function getPath(idToEdit, schema, path = []) {
      PATHCOLLECTOR.push(path);
      for (let i = 0; i < schema.responseOptions.length; i++) {
        const queryItem = schema.responseOptions[i];
        let newpath =
          queryItem.id === idToEdit
            ? [...path, `.responseOptions[${i}]`, true]
            : [...path, `.responseOptions[${i}]`];
        getPath(idToEdit, queryItem, newpath);
      }
    }
    getPath(idToEdit, schemaCopy, []);

    let truePath = [];
    for (let i = 0; i < PATHCOLLECTOR.length; i++) {
      const path = PATHCOLLECTOR[i];
      if (path.indexOf(true) !== -1) {
        truePath = [...path].slice(0, path.indexOf(true));
        truePath.unshift("schemaCopy");
      }
    }
    console.log(truePath);
    let evalString =
      truePath.length > 0
        ? truePath.join("") +
          `${isResponse ? ".response" : ".question"}` +
          `="${newEdit}"`
        : "schemaCopy" +
          `${isResponse ? ".response" : ".question"}` +
          `="${newEdit}"`;

    console.log(evalString);
    eval(evalString);

    if (setIsSavingResponse) {
      let newevalString =
        truePath.length > 0
          ? truePath.join("") + `.responseToSave` + `="${variableToSave}"`
          : "schemaCopy" + `.responseToSave` + `="${variableToSave}"`;

      eval(newevalString);
    } else {
      let newevalString =
        truePath.length > 0
          ? truePath.join("") + `.responseToSave` + `=""`
          : "schemaCopy" + `.responseToSave` + `=""`;
      eval(newevalString);
    }
    setConversationSchema(schemaCopy);
    isResponse
      ? setShowEditResponseModal(false)
      : setShowEditQuestionModal(false);
    setIsUpToDate(false);
    setVariableToSave("");
    setIsSavingResponse(false);
  };

  const getTruePath = (idToEdit, schemaCopy) => {
    const PATHCOLLECTOR = [];
    function getPath(idToEdit, schema, path = []) {
      PATHCOLLECTOR.push(path);
      for (let i = 0; i < schema.responseOptions.length; i++) {
        const queryItem = schema.responseOptions[i];
        let newpath =
          queryItem.id === idToEdit
            ? [...path, `.responseOptions[${i}]`, true]
            : [...path, `.responseOptions[${i}]`];
        getPath(idToEdit, queryItem, newpath);
      }
    }
    getPath(idToEdit, schemaCopy, []);
    let truePath = [];
    for (let i = 0; i < PATHCOLLECTOR.length; i++) {
      const path = PATHCOLLECTOR[i];
      if (path.indexOf(true) !== -1) {
        truePath = [...path].slice(0, path.indexOf(true));
        truePath.unshift("schemaCopy");
      }
    }
    // console.log(truePath);
    return truePath;
  };
  const addPair = () => {
    const schemaCopy = {
      ...conversationSchema,
    };
    const itemToPush = {
      // st.replace(/"/g, '\\"')
      question: questionEdit,
      response: responseEdit,
      responseOptions: [],
      id: uuid(),
    };
    let truePath = getTruePath(schemaIDToAddPair, schemaCopy);
    // console.log(truePath);
    let evalString =
      truePath.length > 0
        ? truePath.join("") +
          `.responseOptions.push(${JSON.stringify(itemToPush)})`
        : `schemaCopy.responseOptions.push(${JSON.stringify(itemToPush)})`;

    console.log(evalString);
    console.log(evalString);
    eval(evalString);
    setConversationSchema(schemaCopy);
    setShowAddQuestionResponsePairModal(false);
    setQuestionEdit("");
    setResponseEdit("");
    setIsUpToDate(false);
  };
  const deletePair = () => {
    const schemaCopy = {
      ...conversationSchema,
    };

    let truePath = getTruePath(schemaIDToDeletePair, schemaCopy);
    console.log(truePath);

    let findDeleteIndexArray = truePath.join("").split(".responseOptions");
    let findArrayToFilter = truePath.join("").split(".");
    let ArrayToFilter =
      findArrayToFilter.slice(0, findArrayToFilter.length - 1).join(".") +
      ".responseOptions";
    let deleteIndex = findDeleteIndexArray[findDeleteIndexArray.length - 1]
      .replaceAll("[", "")
      .replaceAll("]", "");
    console.log(deleteIndex, findDeleteIndexArray, ArrayToFilter);
    let evalString = `${ArrayToFilter}.splice(${deleteIndex}, 1)`;
    console.log(evalString);

    eval(evalString);
    setConversationSchema(schemaCopy);
    setShowDeleteQuestionResponsePairModal(false);
    setIsUpToDate(false);
  };
  const saveSchema = () => {
    // conversationID

    const token = localStorage.getItem("token");

    if (!token) {
      return console.log("not authenticated");
    }
    let config = {
      headers: {
        "x-auth-token": token,
      },
    };

    Axios.post(
      `${GLOBAL.domainConversation}/api/save-schema/${props.match.params.id}`,
      {
        schema: conversationSchema,
      },
      config
    )
      .then((res) => {
        setNotifications({
          name: res.data.message,
        });
        setIsUpToDate(true);
      })
      .catch((err) => {
        setNotifications({
          name: err.response.data.message,
        });
      });
  };

  const bringDownAddMessageResponsePairModal = (schemaIDToAppend) => {
    console.log(schemaIDToAppend);
    setSchemaIDToAddPair(schemaIDToAppend);
    setResponseEdit("");
    setQuestionEdit("");
    setResponseIDEdit("");
    setQuestionEdit("");
    Axios.get(`${GLOBAL.messageFormatter}/api/getAllSchemas`)
      .then((response) => {
        setSchemaRecords(response.data);
        setSearchingForSchemaRecords(false);
      })
      .catch((err) => {
        console.log(err.response);
        setSearchingForSchemaRecords(false);
      });
    setShowAddQuestionResponsePairModal(true);
  };
  const bringDownDeleteMessageResponsePairModal = (schemaIDToAppend) => {
    console.log(schemaIDToAppend);
    setSchemaIDToDeletePair(schemaIDToAppend);
    setShowDeleteQuestionResponsePairModal(true);
  };
  const notification = (
    <div className={classes.Notifications}>
      <p>{notifications.name}</p>
    </div>
  );
  const responseEditModal = (
    <Modal
      show={showEditResponseModal}
      removeModal={() => {
        setShowEditResponseModal(false);
        setIsSavingResponse(false);
        setVariableToSave("");
      }}
    >
      <div className={classes.CreateConvoCont}>
        <h2>Edit Response</h2>
        <div className={classes.InputCont}>
          <textarea
            value={responseEdit}
            onChange={(e) => setResponseEdit(e.target.value)}
            type="text"
            placeholder="Enter a response"
          />
        </div>

        <div className="">
          Save Response as
          <Switch
            checked={isSavingResponse}
            onChange={() => {
              setIsSavingResponse((value) => !value);
            }}
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>

        <div
          className={classes.InputCont}
          style={{
            transform: isSavingResponse ? "none" : "rotateX(90deg)",
            transition: ".2s",
            height: isSavingResponse ? "40px" : "0px",
          }}
        >
          <select
            onChange={(e) => {
              setVariableToSave(e.target.value);
            }}
            value={variableToSave}
          >
            <option value="">-- Select Lead Variable --</option>
            <option value="Zip">Zip</option>
          </select>
        </div>
        <div
          className={[classes.CreateConversation, classes.MarginTop].join(" ")}
        >
          <button disabled={!responseEdit} onClick={() => updateResponse(true)}>
            Update Response
          </button>
        </div>
      </div>
    </Modal>
  );
  const questionEditModal = (
    <Modal
      show={showEditQuestionModal}
      removeModal={() => setShowEditQuestionModal(false)}
    >
      <div className={classes.CreateConvoCont}>
        <h2>Edit Message</h2>
        <div className={classes.InputCont}>
          <textarea
            value={questionEdit}
            onChange={(e) => setQuestionEdit(e.target.value)}
            type="text"
            placeholder="Enter a message"
          />
        </div>
        <p className={classes.SavedMessageSchema}> - or - </p>
        <p className={classes.SavedMessageSchema}>
          Select Saved Message Schemas
        </p>
        <div className={classes.MessageSchemaCont}>
          {schemaRecords &&
            schemaRecords.map((schema) => {
              return (
                <div
                  className={classes.SingleMessage}
                  onClick={() => {
                    setQuestionEdit(schema.message);
                  }}
                >
                  <p>{schema.message}</p>
                </div>
              );
            })}
        </div>
        <div
          className={[classes.CreateConversation, classes.MarginTop].join(" ")}
        >
          <button onClick={() => updateResponse(false)}>Update Message</button>
        </div>
      </div>
    </Modal>
  );

  const AddNewResponseQuestionParModal = (
    <Modal
      show={showAddQuestionResponsePairModal}
      removeModal={() => setShowAddQuestionResponsePairModal(false)}
    >
      <div className={classes.CreateConvoCont}>
        <h2>Add a lead response and a follow up message</h2>
        <div className={classes.InputCont}>
          <textarea
            value={responseEdit}
            onChange={(e) => setResponseEdit(e.target.value)}
            type="text"
            placeholder="Enter lead response (required)"
          />
        </div>
        <div className={classes.InputCont}>
          <textarea
            value={questionEdit}
            onChange={(e) => setQuestionEdit(e.target.value)}
            type="text"
            placeholder="Enter follow up message"
          />
        </div>
        <p className={classes.SavedMessageSchema}> - or - </p>
        <p className={classes.SavedMessageSchema}>
          Select Saved Message Schemas
        </p>
        <div className={classes.MessageSchemaCont}>
          {schemaRecords &&
            schemaRecords.map((schema) => {
              return (
                <div
                  className={classes.SingleMessage}
                  onClick={() => {
                    setQuestionEdit(schema.message);
                  }}
                >
                  <p>{schema.message}</p>
                </div>
              );
            })}
        </div>
        <div
          className={[classes.CreateConversation, classes.MarginTop].join(" ")}
        >
          <button disabled={!responseEdit} onClick={() => addPair()}>
            Update Message
          </button>
        </div>
      </div>
    </Modal>
  );
  const DeleteNewResponseQuestionParModal = (
    <Modal
      show={showDeleteQuestionResponsePairModal}
      removeModal={() => setShowDeleteQuestionResponsePairModal(false)}
    >
      <div className={classes.CreateConvoCont}>
        <h2> Are You sure you want to delete this message pair?</h2>
        <p style={{ textAlign: "center", color: "red" }}>
          **It's children will be deleted as well**
        </p>
        <div
          className={[classes.CreateConversation, classes.MarginTop].join(" ")}
        >
          <button onClick={() => deletePair()}>Confirm</button>
        </div>
      </div>
    </Modal>
  );
  return (
    <Layout>
      <div className={classes.ConversationBuilder}>
        {notifications.hasOwnProperty("name") ? notification : null}
        <Prompt
          when={!isUpToDate}
          message={() =>
            "Some changes made are unsaved, are you sure you want to leave this page? Hit Cancel if you need to save"
          }
        />
        {responseEditModal}
        {questionEditModal}
        {AddNewResponseQuestionParModal}
        {DeleteNewResponseQuestionParModal}
        {loadingStructure ? (
          loader
        ) : (
          <div className={classes.Structure}>
            <div className="">
              <Link to="/conversation-creator"> Back to all Conversations</Link>
            </div>
            <h2 className={classes.ConversationName}>
              Conversation name: {conversationName}
            </h2>

            <div className={classes.SubHeader}>
              <h2 className={classes.ConversationName}>
                Vertical: {vertical}{" "}
              </h2>
              <div
                className={[
                  classes.CreateConversation,
                  classes.SaveAndDelete,
                ].join(" ")}
              >
                <button onClick={saveSchema}>Save</button>
              </div>
            </div>
            <br />
            <div className={[classes.desc].join(" ")}>
              <span style={{ opacity: 0.3 }}>Message to lead</span>
              <p className={classes.MessageDescription}></p>
            </div>
            <div className={[classes.desc].join(" ")}>
              <span style={{ opacity: 0.3 }}>Lead Response</span>
              <p className={classes.ResponseDescription}></p>
            </div>
            <br />
            <ul className={classes.StructureDiagram}>
              <div className={classes.Root}>
                <p
                  className={classes.RootQuery}
                  onClick={() =>
                    editQuestion(
                      conversationSchema.id,
                      conversationSchema.question
                    )
                  }
                >
                  {conversationSchema.question ? (
                    conversationSchema.question
                  ) : (
                    <span style={{ opacity: 0.3 }}>
                      Click to intialize conversation with customer
                    </span>
                  )}
                </p>
                <div className={classes.AddResponseOption}>
                  {!conversationSchema.question ? null : (
                    <F
                      icon={faPlusCircle}
                      onClick={() =>
                        bringDownAddMessageResponsePairModal(
                          conversationSchema.id
                        )
                      }
                    />
                  )}
                </div>
              </div>
              {conversationSchema ? (
                <ConversationStructure
                  conversationSchema={conversationSchema}
                  editQuestion={editQuestion}
                  editResponse={editResponse}
                  addMessageResponsePair={bringDownAddMessageResponsePairModal}
                  deleteMessageResponsePair={
                    bringDownDeleteMessageResponsePairModal
                  }
                />
              ) : null}
            </ul>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default withRouter(ConversationBuilder);
