import React from "react";
import classes from "./ConversationCreator.module.css";
function SingleConversationCard({ conversation, showDeleteModal }) {
  return (
    <div className={classes.SingleConversationCard}>
      <h2>Name: {conversation.name}</h2>
      <p>Vertical: {conversation.vertical}</p>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        className={[classes.CreateConversation, classes.SaveAndDelete].join(
          " "
        )}
      >
        <button
          onClick={() => {
            window.location.href = `/conversation-creator/${conversation._id}`;
          }}
          style={{ width: 90, marginBottom: 20 }}
        >
          Edit
        </button>
        <div className="">
          <button
            onClick={() => showDeleteModal(conversation)}
            style={{ width: 100, background: "#f73a63" }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default SingleConversationCard;
