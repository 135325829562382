import React, { createContext, useState, useEffect } from "react";

import GLOBAL from "../containers/GLOBAL/GLOBAL"
import REQ from "../helperFunctions/REQ";
import { useAlert } from 'react-alert';
// import  GLOBAL from "../containers/GLOBAL/GLOBAL";
export const AuthUserContext = createContext();

function AuthUserContextProvider(props) {
    const [user, setuser] = useState({
        email: ""
    });
    const [profitsharer, setprofitsharer] = useState({});
    const [loading, setloading] = useState(true)
    const myAlert = useAlert();

    useEffect(() => {
        getuser()

    }, [])
    const getuser = async () => {
        try {
            const res = await REQ('get', `${GLOBAL.domainNameCheap}/api/auth/auto`, null, true)

            let user = res.data
            let res2 = await REQ(
                "POST",
                `${GLOBAL.domainDataowner}/api/get-single-profit-sharer`,
                { name: user.username },
                true
            )
            // console.log(res2, user.username, 32)
            let psharer = res2.data
            setuser(res.data)
            setprofitsharer(psharer)
            setloading(false)

        } catch (error) {

            console.log(error)
            setloading(false)
        }
    }




    return (
        <AuthUserContext.Provider
            value={{
                getuser,
                user,
                profitsharer,
                loading,
            }}
        >
            {props.children}
        </AuthUserContext.Provider>
    );
}

export default AuthUserContextProvider;
