import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store/createdStore';

import { Provider } from 'react-redux';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import NumberChangeContextProvider from "./context/numberchangers";
import AuthUserContextProvider from "./context/auth";
import AdvertiserContextProvider from "./context/advertisers";
import CreateSubIDContextProvider from './context/createSubID';


const options = {
	// you can also just use 'bottom center'
	position: positions.BOTTOM_CENTER,
	timeout: 5000,
	offset: '30px',
	// you can also just use 'scale'
	transition: transitions.SCALE
};
const app = (
	<Provider store={store}>

		<AlertProvider template={AlertTemplate} {...options}>

			<NumberChangeContextProvider>
				<AuthUserContextProvider>
					<CreateSubIDContextProvider>
						<AdvertiserContextProvider>
							<BrowserRouter>
								<App />
							</BrowserRouter>
						</AdvertiserContextProvider>
					</CreateSubIDContextProvider>
				</AuthUserContextProvider>
			</NumberChangeContextProvider>
		</AlertProvider>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
