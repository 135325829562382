//@ts-nocheck
import React, { useState, useEffect, useContext, } from "react";
import { AuthUserContext } from "../../context/auth"
import { Offline, Online } from "react-detect-offline";

import classes from "./Layout.module.css";
import { NumberChangeContext } from "../../context/numberchangers";
import MessageSenderNav from "./MessageSenderNav"
import LegalNav from "./LegalNav"
import Logo from "./logo2.png";
import tk from "../../images/tk.gif";
import { withRouter } from "react-router-dom";
// import LoadScreen from "../../containers/LoadScreen/LoadScreen";
import MoneySound from "../../assets/MONEYWIN.wav"
import REQ from "../../helperFunctions/REQ";
import GLOBAL from "../../containers/GLOBAL/GLOBAL";
import Sidebar from "./Sidebar";
import ShareHolderNav from "./ShareHolderNav";
import PartnerNav from "./PartnerNav";
import { formatter } from "../../helperFunctions/currencystring";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faMoneyBill, faMoneyBillAlt, faMoneyBillWaveAlt, faNewspaper, faRecycle, faRedo } from "@fortawesome/free-solid-svg-icons";
// import { canvasStyles } from '../../constants/styles';
// import ReactCanvasConfetti from 'react-canvas-confetti';


function Layout({ children, history }) {
  let [showingMessageDropdown, setShow] = useState(false);
  let [loadingScreen, setShowLoadingScreen] = useState(true);
  let [status, setStatus] = useState(true);
  let [audio] = useState(new Audio(MoneySound));
  let [playing, setPlaying] = useState(false);
  let [issender, setissender] = useState(false);
  // let [socket] = useState(SocketIOClient(GLOBAL.domainClickSocket));
  let [fullName, setFullName] = useState("");
  let [userid, setuserid] = useState("");
  let [balance, setbalance] = useState("");
  let [revtoday, setrevtoday] = useState(0);
  let [revyesterday, setrevyesterday] = useState(0);
  let [viewerid, setviewerid] = useState("");
  let [allowBLAAcess, setallowBLAAcess] = useState(false);
  let [noconvstats, setnoconvstats] = useState(false)
  let [sidebar, showSideBar] = useState(false);
  let [revloading, setrevloading] = useState(false);
  let [advertiserId, setAdvertiserId] = useState(null)
  let [showingraw, setshowrawsub] = useState(false);
  let [pendingbalance, setpendingbalance] = useState(0);
  const {
    balancechart,
    setbalancechart,
    // getInstance,
  } = useContext(NumberChangeContext);


  const {
    user,
    profitsharer
  } = useContext(AuthUserContext)
  useEffect(() => {
    const {
      _id,
      fullName,
      status,
      balance,
      allowBLAAcess,
      no_conversion_stats,
      showrawsub,
      advertiser_id,
      issender
    } = user;


    // console.log({issender})
    setissender(issender)
    setshowrawsub(showrawsub)
    setuserid(_id);
    setnoconvstats(no_conversion_stats || false)
    if (profitsharer?._id) {
      console.log("fetching balancechart");
      setviewerid(profitsharer._id);
      fetchpaydata(profitsharer?._id, status);
    }

    if (advertiser_id) {
      setAdvertiserId(advertiser_id)
    }
    if (status === "number-changer") {
      fetchNumberChangerBalance()
    }

    setFullName(fullName);
    setallowBLAAcess(allowBLAAcess);
    if (status !== "superadmin" && !allowBLAAcess && !issender) {
      const pathnames = [
        "/twilio",
        "/gorilla",
        "/twilio-account-2",
        "/twilio-account-2-beta",
        "/namecheap",
        "/blacklist",
        "/formatter",
        "/myscrubs",
      ];

      if (pathnames.indexOf(history.location.pathname) !== -1) {
        return (document.location.href = "/404");
      }
    } else if (status === "superadmin") {
      const pathnames = [
        "/statistics",
      ];

      if (pathnames.indexOf(history.location.pathname) !== -1) {
        return (document.location.href = "/404");
      }
    }
    if (status === "viewer") {
      const pathnames = [
        "/conversion-tracker",
        "/landers",
        "/sms",
        "/sending-numbers",
        "/gorilla",
        "/twilio-account-2",
        "/twilio-account-2-beta",
        "/twilio",
        "/blacklist",
      ];
      if (pathnames.indexOf(history.location.pathname) !== -1) {
        return (document.location.href = "/404");
      }
    }
    setStatus(status);
    fetchConversions(status)
    setbalance(balance);
    setShowLoadingScreen(false);

  }, []);


  const fetchpaydata = async (id, status) => {
    let url = `${GLOBAL.domainDataowner}/api/payment-summary-affilliate/${id}`

    if(status === "partner"){
      url = `${GLOBAL.domainDataowner}/api/payment-summary-partner/${id}`
    }
    try {
      let data = await REQ(
        "GET",
        url,
        null,
        true
      );

      data = data.data;

      // console.log(data);
      setbalancechart(data?.totalowed);
      setpendingbalance(data?.pendingBalance || 0)
    } catch (error) {
      // alert(error?.response?.data?.message || "an error occured");
      console.log(error)
    }
    // setloading(false);
  };


  const fetchConversions = async(status) =>{
    // fetchConversions

  try {
    setrevloading(true)

    let url = `${GLOBAL.domainClickGet}/api/conversions-all?limit=8&isdetailed=no&fixedRangeValue=0`
    let {data} = await REQ(
      "GET",
      url,
      null,
      true
    );
    // const {data} = await Axios.get( , {

    // })

    setrevtoday(data.totalPayout)


    // console.log({dailyConversion: data})

  } catch (error) {
    console.log(error);
    
  } finally{
    setrevloading(false)
  }
  //
  }

  const fetchNumberChangerBalance = async () => {
    try {

      let data = await REQ(
        "GET",
        `${GLOBAL.domain}/api/numberchangerpayments/balance`,
        null,
        true
      );

      data = data.data;

      // console.log(data);
      setbalancechart(data?.balance);
    } catch (error) {
      console.log(error)
      // alert(error?.response?.data?.message || "an error occured");
    } finally{
      setrevloading(false)
    }
    // setloading(false);
  };
  return (
    <>

      <Sidebar
        status={status}
        showingsidebar={sidebar}
        setSideBar={showSideBar}
        pendingbalance={pendingbalance}
        balancechart={balancechart}
        viewerid={viewerid}
      />
      <div className={classes.Layout}>
        {/* <LinearProgress value={100} variant="determinate" /> */}
        {status == "superadmin" && <div className={classes.RevDiv}>
        <p className={classes.RevToday} style={{marginRight: 10}}>Today: {revloading? <img src={tk} height={15} style={{marginRight: 10}}/> :<span>{  formatter.format(`${revtoday}`)} </span> }<FontAwesomeIcon style={{marginRight: 10}} icon={faRedo} onClick={()=>{
          fetchConversions()
        }}/>   <FontAwesomeIcon icon={faArrowCircleRight} onClick={()=>{
          window.location.href = "/super/allrev"
        }} /></p>
     
        </div>}
        <div className={classes.Header}>
          <p className={classes.logo}>
            <a href="/">
              <img width="200px" src={Logo} />
            </a>
          </p>
        
          {status == "superadmin" || issender? (
            <ul className={classes.DesktopOnly}>
              <div className={classes.MessagingAppsCont}>
                <li onClick={() => setShow(!showingMessageDropdown)}>
                  Messaging Apps
                </li>
                <div className={classes.MessagingApps}>
                  <a href="/sendingphones">
                    <div className={classes.fullWidth}>My SMS Sims</div>
                  </a>
                  <a href="/sms/reset-number-changes">
                    <div className={classes.fullWidth}>Reset Numbers</div>
                  </a>
                  <a href="/sms/moneyapp/users">
                    <div className={classes.fullWidth}>Moneyapp Users</div>
                  </a>
                  <a href="/sms/enterprise/sims">
                    <div className={classes.fullWidth}>Enterprise Sims</div>
                  </a>
                  <a href="/sms/textback">
                    <div className={classes.fullWidth}>Text Back Sender</div>
                  </a>
                  <a href="/sending-numbers">
                    <div className={classes.fullWidth}>Track Number Changes</div>
                  </a>

                  <a href="/conversation-creator">
                    <div className={classes.fullWidth}>My Conversations</div>
                  </a>

                  <div>
                    <a href="/sms/homeport">
                      <div className={classes.fullWidth}>
                        In-House SMS Platform
                      </div>
                    </a>
                  </div>
                  <div>
                    <a href="/sms/homeport-minis">
                      <div className={classes.fullWidth}>
                        In-House SMS Minis
                      </div>
                    </a>
                  </div>
                  <div>
                    <a href="/sms/homeport-512">
                      <div className={classes.fullWidth}>
                        In-House SMS 512
                      </div>
                    </a>
                  </div>
                  <div>
                    <a href="/sms/moneyapp/send">
                      <div className={classes.fullWidth}>
                        SMS With Money App
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <li>
                <a href="/namecheap">Bulk Forward</a>
              </li>
              <li>
                <a href="/my-number-changers">Number Changers</a>
              </li>
              <li>
                <a href="/blacklist">Blacklist</a>
              </li>
              <li>
                <a href="/click-tracker">Clicks</a>
              </li>
              <li>
                <a href="/conversion-tracker">Conversions</a>
              </li>
              <li>
                <a href="/legal/leads">Optin Info</a>
              </li>
              <li>
                <a href="/advertisers">Advertisers</a>
              </li>
              <li>
                <a href="/message-formatter">Formatter</a>
              </li>
              <li>
                <a href="/cidattach/files">CID Attacher</a>
              </li>
              <li>
                <a href="/subid-create">Create Sub</a>
              </li>
              <li>
                <a href="/profit-sharers">Profit Sharers</a>
              </li>
              <li>
                <a
                  onClick={() => localStorage.removeItem("token")}
                  href="/auth"
                >
                  Logout
                </a>
              </li>
            </ul>
          ) : status === "viewer" ? (
            <ul className={classes.navs} style={{
              display: "flex",
              alignItems:"center"
            }}>
             <li className={classes.DesktopOnly}> <p style={{padding: 0, margin: 0}}>Pending Balance: ${pendingbalance.toFixed(2)}</p></li>

              {!noconvstats ? <li className={classes.DesktopOnly}>
                <a href={showingraw ? "/my-statistics" : "/statistics"}>Conversion Stats</a>
              </li> : null}
           
              <li  className={classes.DesktopOnly}>
                <a
                  onClick={() => localStorage.removeItem("token")}
                  href="/auth"
                >
                  Logout
                </a>
              </li>
              <div className={[classes.UserName, classes.DesktopOnly].join(" ")}>{fullName.substring(0, 1)}</div>
              {viewerid ? (
                <li className={[classes.BalanceButton, classes.DesktopOnly].join(" ")}>
                  <a href={`/balance/` + viewerid}>
                    Balance: $ {balancechart.toFixed(2)}
                  </a>
                </li>
              ) : (
                  null
                )}
            </ul>
          ) : status === "partner"?
           <div className={classes.DesktopOnly}> <PartnerNav balancechart={balancechart} pendingbalance={pendingbalance}  viewerid={viewerid} />
           </div>    :
                          (
                            <ul>

                              <li>
                                <a
                                  onClick={() => localStorage.removeItem("token")}
                                  href="/auth"
                                >
                                  Logout
                           </a>
                              </li>


                            </ul>
                          )
      
            }
              <div
            className={classes.SnackBar}
            onClick={() => showSideBar(!sidebar)}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div className={classes.ChildrenPanel}>
          <p>  <Offline>You are not currently connected to the Internet.Some services may not function properly</Offline></p>
          {children}</div>
      </div>
    </>
  );
}

export default withRouter(Layout);
