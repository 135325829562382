// @ts-nocheck
import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import Modal from "../../components/Modal/Modal";
import classes from "./ConversationCreator.module.css";
import Skeleton from "react-loading-skeleton";
import Axios from "axios";
import GLOBAL from "../GLOBAL/GLOBAL";
import SingleConversationCard from "./SingleConversationCard";

function ConversationCreator() {
  const [conversationList, setConversationList] = useState(null);
  const [constantConversations, setConstantConversations] = useState(null);
  const [conversationTraffic, setConversationTraffic] = useState("");
  const [conversationName, setConversationName] = useState("");
  const [showNewConversationModal, setShowNewConversationModal] = useState(
    false
  );
  const [trafficList, setTrafficList] = useState([]);
  const [conversationCreationError, setConversationCreationError] = useState(
    ""
  );

  const [showingDeleteModalNow, setShowingDeleteModalNow] = useState(false);
  const [conversationToDelete, setConversationToDelete] = useState("");
  const [notifications, setNotifications] = useState({});
  const [conversationSearchValue, setConversationSearchValue] = useState("");

  useEffect(() => {
    if (notifications.hasOwnProperty("name")) {
      setTimeout(() => {
        setNotifications({});
      }, 9000);
    }
  }, [notifications]);
  useEffect(() => {
    fetchData();
    Axios.get(`${GLOBAL.domainNameCheap}/api/gettraffic`).then((res) => {
      setTrafficList(res.data.reverse());
    });
  }, []);

  const fetchData = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      return console.log("unauthorized");
    }
    const config = {
      headers: {
        "x-auth-token": token,
      },
    };
    Axios.get(`${GLOBAL.domainConversation}/api/get-conversation`, config).then(
      (res) => {
        const conversationList = res.data;
        console.log(conversationList);
        setConversationList(res.data);
        setConstantConversations(res.data);
      }
    );
  };

  async function createNewConversation() {
    setConversationCreationError("");
    const token = localStorage.getItem("token");

    if (!token) {
      return console.log("unauthorized");
    }
    const config = {
      headers: {
        "x-auth-token": token,
      },
    };

    const conversation = {
      vertical: conversationTraffic,
      name: conversationName,
    };

    try {
      const { data } = await Axios.post(
        `${GLOBAL.domainConversation}/api/create-new-conversation`,
        conversation,
        config
      );

      console.log(data, 45);

      if (data.success) {
        window.location.href = `/conversation-creator/${data.newConversation._id}`;
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error.response.data.error) {
        setConversationCreationError(error.response.data.message);
      }
    }
  }
  const showDeleteModal = (id) => {
    setShowingDeleteModalNow(true);
    setConversationToDelete(id);
  };
  const deleteConversation = async () => {
    setConversationCreationError("");
    const token = localStorage.getItem("token");

    if (!token) {
      return console.log("unauthorized");
    }
    const config = {
      headers: {
        "x-auth-token": token,
      },
    };

    try {
      const { data } = await Axios.get(
        `${GLOBAL.domainConversation}/api/delete/${conversationToDelete._id}`,
        config
      );

      console.log(data, 45);

      if (data.success) {
        // alert(data.message);
        setNotifications({
          name: data.message,
        });
        fetchData();
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error.response.data.error) {
        setConversationCreationError(error.response.data.message);
      }
    }
    setShowingDeleteModalNow(false);
  };
  const createModal = (
    <Modal
      show={showNewConversationModal}
      removeModal={() => setShowNewConversationModal(false)}
    >
      <div className={classes.CreateConvoCont}>
        <p style={{ color: "red", textAlign: "center" }}>
          {conversationCreationError}
        </p>
        <h2>Create New Conversation</h2>
        <div className={classes.InputCont}>
          <input
            value={conversationName}
            onChange={(e) => setConversationName(e.target.value)}
            type="text"
            placeholder="Enter New Conversation Name"
          />
        </div>
        <div className={classes.InputCont}>
          <select
            value={conversationTraffic}
            onChange={(e) => setConversationTraffic(e.target.value)}
          >
            <option value="">Select Conversation Vertical</option>
            {trafficList &&
              trafficList.map((traffic) => (
                <option key={traffic} value={traffic}>
                  {traffic}
                </option>
              ))}
          </select>
        </div>
        <div
          className={[classes.CreateConversation, classes.MarginTop].join(" ")}
        >
          <button
            disabled={!conversationTraffic || !conversationName}
            onClick={createNewConversation}
          >
            Create Conversation
          </button>
        </div>
      </div>
    </Modal>
  );

  const notification = (
    <div className={classes.Notifications}>
      <p>{notifications.name}</p>
    </div>
  );
  const deleteConversationModal = (
    <Modal
      show={showingDeleteModalNow}
      removeModal={() => setShowingDeleteModalNow(false)}
    >
      <div className={classes.CreateConvoCont}>
        <p style={{ color: "red", textAlign: "center" }}>
          {conversationCreationError}
        </p>
        <h2>Are you sure you want to delete {conversationToDelete.name}? </h2>

        <p style={{ color: "red", textAlign: "center" }}>
          ** Already existing conversations with this schema will be
          discontinued **
        </p>
        <div
          className={[classes.CreateConversation, classes.MarginTop].join(" ")}
        >
          <button onClick={deleteConversation}>Delete Conversation</button>
        </div>
      </div>
    </Modal>
  );

  return (
    <Layout>
      {notifications.hasOwnProperty("name") ? notification : null}

      {createModal}
      {deleteConversationModal}
      <div className={classes.ConversationCreator}>
        <div className={classes.Header}>
          <h2>My Conversations</h2>
          <div className={classes.CreateConversation}>
            <button onClick={() => setShowNewConversationModal(true)}>
              New Conversation +{" "}
            </button>
          </div>
        </div>
        <div
          className={[
            classes.SearchBar,
            classes.InputCont,
            classes.FullBorder,
          ].join(" ")}
        >
          <input
            type="text"
            value={conversationSearchValue}
            placeholder="Search for Conversation"
            onChange={(e) => {
              setConversationSearchValue(e.target.value);

              if (!e.target.value) {
                setConversationList(constantConversations);
                return;
              }
              let listOfConversations = [...constantConversations];

              listOfConversations = listOfConversations.filter(
                (dataowner) =>
                  dataowner.name
                    .toLowerCase()
                    .indexOf(e.target.value.toLowerCase()) !== -1
              );

              setConversationList(listOfConversations);

              //   this.setState({ listOfDataowners });
            }}
          />
        </div>
        <div className={classes.Body}>
          {/* <h1>You have no conversations</h1>

          <div className={classes.CreateConversation}>
            <button>Create Your First Conversation </button>
          </div> */}
          {!conversationList ? (
            <>
              <div style={{ fontSize: 50, lineHeight: 1.1, marginTop: 30 }}>
                <Skeleton count={1} />
              </div>
              <div style={{ fontSize: 350, lineHeight: 1.1, marginTop: 30 }}>
                <Skeleton count={1} />
              </div>
            </>
          ) : conversationList && conversationList.length <= 0 ? (
            <>
              <h1 className={classes.NoConvoHeader}>
                You have no conversations
              </h1>

              <div className={classes.CreateConversation}>
                <button onClick={() => setShowNewConversationModal(true)}>
                  {" "}
                  Create Your First Conversation{" "}
                </button>
              </div>
            </>
          ) : (
            conversationList &&
            conversationList.map((conversation) => {
              return (
                <SingleConversationCard
                  key={conversation._id}
                  conversation={conversation}
                  showDeleteModal={showDeleteModal}
                />
              );
            })
          )}
        </div>
      </div>
    </Layout>
  );
}

export default ConversationCreator;
