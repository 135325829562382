import React from 'react';
import classes from "./Layout.module.css";

const PartnerNav = ({ viewerid, balancechart, pendingbalance }) => {

    return (
        <ul style={{display: "flex", alignItems: "center"}}>
            <li>
                <p>Pending Balance:  ${pendingbalance <=0 ? "0.00":  pendingbalance.toFixed(2)}</p>
            </li>
          
                <li >
                  <a href={`/conversion-tracker`}>
                  Our Campaigns
                  </a>
                </li>

                <li>

<a onClick={() => localStorage.removeItem("token")} href="/auth">
    Logout
</a>
</li>
                <li className={classes.BalanceButton}>
                  <a href={`/partner/balance/` + viewerid}>
                    Balance: $ {balancechart.toFixed(2)}
                  </a>
                </li>
         


       
        </ul>
    )
}


export default PartnerNav