import React, { useState, useEffect } from 'react'
import Layout from "../../components/Layout/Layout";
import classes from "./EnterpriseNumberChange.module.css"
import Skeleton from "react-loading-skeleton";
import GLOBAL from '../GLOBAL/GLOBAL';
import REQ from '../../helperFunctions/REQ';
import MyModal from "../../components/MyModal/MyModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryFull, faCopyright, faPause, faRecycle, faRedo, faRedoAlt, faStopCircle, faTrafficLight, fas } from '@fortawesome/free-solid-svg-icons';
import { useAlert } from 'react-alert'
import SimList from './SimList';
import { Button, Input, Select } from '@material-ui/core';
import PromptModal from './PromptModal';
import UploadNumbersFile from './UploadNumbersFile';
import axios from "axios"
// import OnlyFailedSims from './OnlyFailedSims';

export default function EnterpriseNumberChange() {
  const [numbers, setnumbers] = useState([])
  const [numbersindividual, setnumbersindividual] = useState([])
  const [selectedPhone, setSelectedPhone] = useState("")
  const [areacode, setareacode] = useState("")
  const [search, setsearch] = useState("")
  const [changingnumberone, setchangingnumberone] = useState(false)
  const [pausenumberone, setpausenumberone] = useState(false)
  const [changingnumberall, setchangingnumberall] = useState(false)
  const [changereadymodalshow, setchangereadymodalshow] = useState(false)
  const [changingnumberfail, setchangingnumberfail] = useState(false)
  const [statustosync, setstatustosync] = useState("")
  const [rechangestuck, setrechangestuck] = useState(false)
  const [resync, setresync] = useState(false)
  const [actionsShow, setActionsShow] = useState(false)
  const [loading, setloading] = useState(true)
  const [page, setpage] = useState(0);
  const [changing, setchanging] = useState(0);
  const [changingonly, setchangingonly] = useState(0);
  const [changed, setchanged] = useState(0);
  const [fail, setfail] = useState(0);
  const [total, settotal] = useState(0);
  const [ready, setready] = useState(0);
  const [syncing, setsyncing] = useState(0);
  const [paused, setpaused] = useState(0);
  const [loadingnext, setloadingnext]= useState(false);
  const [machine, setmachine] = useState("")
  const [status, setstatus] = useState("")
  const [set, setset] = useState("");
  const [setgroup, setsetgroup] = useState([]);
  const [settochange, setsettochange] = useState("");
  const [opensetmodal, setopensetmodal] = useState(false);
  const [targetedpausemodalshow, settargetedpausemodalshow] = useState(false);
  const [numbersuploadmodal, setnumbersuploadmodal] = useState(false);
  // const [error] = useState("");

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  useEffect(() => {
    // Use a timer to delay the execution of the search function
    const debounceTimer = setTimeout(() => {
      setDebouncedSearchTerm(search);
    }, 500); // Adjust the delay time (in milliseconds) as needed

    // Clear the timer on each keystroke to restart the countdown
    return () => clearTimeout(debounceTimer);
  }, [search]);

  useEffect(()=>{
      fetchNumbers(0);
      console.log("machine")
  },[machine])
  useEffect(() => {
      fetchNumbers(0)
      console.log("debounce")

  }, [debouncedSearchTerm]);

  useEffect(()=>{
    fetchNumbers(0)
    console.log("status")

  },[status]);
  useEffect(()=>{
    console.log("set")

    fetchNumbers(0)
  },[set]);

  const myAlert = useAlert()


  const fetchNumbers = async (number = 0, pstatus = "") => {
    try {

      // setstatus(status);
      getSets()
      console.log({number})
      if(number == 0 || !number){
          setloading(true)
          setpage(0);// page  is zero

          // sets
          console.log("I am setting numberfs to nothing please")
          setnumbers([])
          setnumbersindividual([])

          

        }
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers?limit=30&page=${number}&machine=${machine}&search=${search}&status=${status}&set=${set}`, null, true)
        // setnumbers(res?.data)
        if(number == 0 ){
          console.log("its zero") 
          setnumbers(res?.data?.numbers)
          setnumbersindividual(res?.data?.numbersindividual)
          setfail(res?.data?.fail)
          settotal(res?.data?.total);
          setchanging(res?.data?.changing)
          setchangingonly(res?.data?.changingonly)
          setchanged(res?.data?.changed)
          setpaused(res?.data?.paused)
          setready(res?.data?.ready)
          setsyncing(res?.data?.syncing)
        } else{
          setnumbers((n)=>n.concat(res?.data?.numbers))
          setnumbersindividual(ni=> ni.concat(res?.data?.numbersindividual))
          setfail(res?.data?.fail)
          settotal(res?.data?.total);
          setchangingonly(res?.data?.changingonly)

          setchanging(res?.data?.changing)
          setchanged(res?.data?.changed)
          setpaused(res?.data?.paused)
          setready(res?.data?.ready)
          setsyncing(res?.data?.syncing)




        }

        console.log(res)
        setpage(number)
    } catch (error) {
      console.log(error);

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not access codes", {
            type: 'error'
        });

    } finally {
        setloading(false)
        setloadingnext(false)
    }
}

  const bringModalNumberchange = (phone)=>{
    if(!areacode){
      return   myAlert.show( "Area code cannot be empty", {
        type: 'error'
    });
    }
    setSelectedPhone(phone)
    setchangingnumberone(true);
  }
  const bringModalNumberchangeAll = (phone)=>{
    if(!areacode){
      return   myAlert.show( "Area code cannot be empty", {
        type: 'error'
    });
    }
    setSelectedPhone("")
    setchangingnumberone(false);
    setchangingnumberall(true);
  }
  const bringModalNumberpause = (phone)=>{

    setSelectedPhone(phone)
    setpausenumberone(true);
  }
  const bringModalNumberchangeFail = (phone)=>{
    if(!areacode){
      return   myAlert.show( "Area code cannot be empty", {
        type: 'error'
    });
    }
    setSelectedPhone("")
    setchangingnumberone(false);
    setchangingnumberall(false);
    setchangingnumberfail(true);
  }
  const bringModalNumberchangeReady = (phone)=>{
    if(!areacode){
      return   myAlert.show( "Area code cannot be empty", {
        type: 'error'
    });
    }
    setSelectedPhone("")
    setchangingnumberone(false);
    setchangingnumberall(false);
    setchangereadymodalshow(true);
  }
  const bringModalPauseTarget = (phone)=>{
  
    setSelectedPhone("")
    setchangingnumberone(false);
    setchangingnumberall(false);
    settargetedpausemodalshow(true);
  }
  const bringModalRechangeStuck = (phone)=>{
    if(!areacode){
      return   myAlert.show( "Area code cannot be empty", {
        type: 'error'
    });
    }
    setSelectedPhone("")
    setchangingnumberone(false);
    setchangingnumberall(false);
    setchangingnumberfail(false);

    setrechangestuck(true);
  }
  const bringModalSync = (phone)=>{
    // if(!areacode){
    //   return   myAlert.show( "Area code cannot be empty", {
    //     type: 'error'
    // });
    // }
    setSelectedPhone("")
    setchangingnumberone(false);
    setchangingnumberall(false);
    setchangingnumberfail(false);

    setresync(true);
  }
  const changeOne = async () => {

    if(!areacode){
      return   myAlert.show( "Area code cannot be empty", {
        type: 'error'
    });
    }
    try {
        // setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/changeone?number=${selectedPhone?.number}&areacode=${areacode}`, null, true)

        fetchNumbers();
    
        setchangingnumberone(false);
        setchangingnumberall(false);
        setchangingnumberfail(false);

        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not schedule change", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const pauseOne = async () => {


    try {
        // setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/pauseone?number=${selectedPhone?.number}`, null, true)
        // setnumbers(res?.data)
        // setnumbers(res?.data?.numbers || [])
        fetchNumbers();
        setchangingnumberone(false);
        setchangingnumberall(false);
        setchangingnumberfail(false);
        setpausenumberone(false);
        // setphones(res?.data?.phonesAssociated || [])

        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not schedule change", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const pauseAll = async (setChange) => {


    try {
        // setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/pauseall?set=${setChange}`, null, true)
        // setnumbers(res?.data)
        // setnumbers(res?.data?.numbers || [])
        fetchNumbers();
        setchangingnumberone(false);
        setchangingnumberall(false);
        setchangingnumberfail(false);
        setpausenumberone(false);
        // setphones(res?.data?.phonesAssociated || [])

        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not schedule change", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const resumeall = async (setChange) => {


    try {
        // setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/resumeall?set=${setChange}`, null, true)
        // setnumbers(res?.data)
        // setnumbers(res?.data?.numbers || [])
        fetchNumbers();
        setchangingnumberone(false);
        setchangingnumberall(false);
        setchangingnumberfail(false);
        setpausenumberone(false);
        // setphones(res?.data?.phonesAssociated || [])

        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not schedule change", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const changeAll = async () => {
    if(!areacode){
      return   myAlert.show( "Area code cannot be empty", {
        type: 'error'
    });
    }
    try {
        setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/changeall?areacode=${areacode}&set=${settochange}`, null, true)
        fetchNumbers();
        // setphones(res?.data?.phonesAssociated || [])
        myAlert.show("Scheduled Number change for all numbers", {
          type: "success"
        })

        setchangingnumberone(false);
        setchangingnumberall(false);
        setchangingnumberfail(false);
        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not schedule change", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const changeFailed = async () => {

    if(!areacode){
      return   myAlert.show( "Area code cannot be empty", {
        type: 'error'
    });
    }
    try {
        setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/changeallfailed?areacode=${areacode}&set=${settochange}`, null, true)
        fetchNumbers();

        setchangingnumberone(false);
        setchangingnumberall(false);
        setchangingnumberfail(false);
        // setphones(res?.data?.phonesAssociated || [])
        myAlert.show("Scheduled Number change for all failed changes", {
          type: "success"
        })
        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not schedule change", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const changeReady = async () => {

    if(!areacode){
      return   myAlert.show( "Area code cannot be empty", {
        type: 'error'
    });
    }
    try {
        setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/changeallready?areacode=${areacode}&set=${settochange}`, null, true)
        fetchNumbers();

        setchangingnumberone(false);
        setchangingnumberall(false);
        setchangingnumberfail(false);
        setchangereadymodalshow(false)
        // setphones(res?.data?.phonesAssociated || [])
        myAlert.show("Scheduled Number change for all ready numbers", {
          type: "success"
        })
        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not schedule change", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const  changeStuck = async () => {

    if(!areacode){
      return   myAlert.show( "Area code cannot be empty", {
        type: 'error'
    });
    }
    try {
        setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/changeallstuck?areacode=${areacode}&set=${settochange}`, null, true)
        fetchNumbers();

        setchangingnumberone(false);
        setchangingnumberall(false);
        setchangingnumberfail(false);
        setrechangestuck(false);
        // setphones(res?.data?.phonesAssociated || [])
        myAlert.show("Scheduled Number change for all stuck changes in queue", {
          type: "success"
        })
        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not schedule change", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const  resyncNumbers = async () => {

    // if(!areacode){
    //   return   myAlert.show( "Area code cannot be empty", {
    //     type: 'error'
    // });
    // }
    try {
        setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/resync?set=${settochange}&status=${statustosync}`, null, true)
        fetchNumbers();

        setchangingnumberone(false);
        setchangingnumberall(false);
        setchangingnumberfail(false);
        setresync(false);
        // setphones(res?.data?.phonesAssociated || [])
        myAlert.show("Scheduled Resyncing of numbers", {
          type: "success"
        })
        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not schedule resync", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const  stopResync = async () => {

    // if(!areacode){
    //   return   myAlert.show( "Area code cannot be empty", {
    //     type: 'error'
    // });
    // }
    try {
        setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/resyncstop?set=${settochange}`, null, true)
        fetchNumbers();

        // setchangingnumberone(false);
        // setchangingnumberall(false);
        // setchangingnumberfail(false);
        // setresync(false);
        // setphones(res?.data?.phonesAssociated || [])
        myAlert.show(" Resyncing of numbers stopped", {
          type: "success"
        })
        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not stop resync", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const  pauseTarget = async () => {

    // if(!areacode){
    //   return   myAlert.show( "Area code cannot be empty", {
    //     type: 'error'
    // });
    // }
    try {
        setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/pausetarget?set=${settochange}&status=${statustosync}`, null, true)
        fetchNumbers();

        // setchangingnumberone(false);
        // setchangingnumberall(false);
        // setchangingnumberfail(false);
        // setresync(false);
        // setphones(res?.data?.phonesAssociated || [])
        myAlert.show(" Resyncing of numbers stopped", {
          type: "success"
        })
        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not stop resync", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}
  const  stopchanging = async () => {

    // if(!areacode){
    //   return   myAlert.show( "Area code cannot be empty", {
    //     type: 'error'
    // });
    // }
    try {
        setloading(true)
        const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/numbers/stopchanging`, null, true)
        // fetchNumbers();

        // setchangingnumberone(false);
        // setchangingnumberall(false);
        // setchangingnumberfail(false);
        // setresync(false);
        // setphones(res?.data?.phonesAssociated || [])
        myAlert.show(" Changing of numbers stopped", {
          type: "success"
        })
        console.log(res)

    } catch (error) {

        const message = error?.response?.data?.message;
        // seterror(true)
        myAlert.show(message || "Could not stop the changing of numbers", {
            type: 'error'
        });

    } finally {
        setloading(false)
    }
}

const addNewSet = async ()=>{

  try {
      setloading(true)
      const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/sims/create/set`, null, true)
      setopensetmodal(false)
      // setphones(res?.data?.phonesAssociated || [])
      myAlert.show("New Set Created", {
        type: "success"
      })
      console.log(res)
      getSets();


  } catch (error) {

      const message = error?.response?.data?.message;
      // seterror(true)
      myAlert.show(message || "Could not create new set", {
          type: 'error'
      });


  } finally {
      setloading(false)
  }
}
const getSets = async ()=>{

  try {
      setloading(true)
      const res = await REQ('GET', `${GLOBAL.domain}/api/enterprise/sims/sims/fetch/set`, null, true)
      // setopensetmodal(false)
      // setphones(res?.data?.phonesAssociated || [])

      console.log(res)

      setsetgroup(res.data);


  } catch (error) {

      const message = error?.response?.data?.message;
      // seterror(true)
      myAlert.show(message || "Could not fetch sets", {
          type: 'error'
      });

  } finally {
      setloading(false)
  }
}

const uploadNumberFile = async  (set, file)=>{
  try {
    // console.log(event.target.files[0]);
    // const data = new FormData();
    // data.append("file", event.target.files[0]);
    // setIsLoadingScrubUpload(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append("set", set)
    const {data }  = await axios
      .post(`${GLOBAL.domain}/api/enterprise/sims/sims/upload/set`, formData)
    

      console.log(data);

      setnumbersuploadmodal(false)
      myAlert.show("Successfully Scheduled Number Attach", {
        type: 'success'
    });


  } catch (error) {
    console.log(error);
    const message = error?.response?.data?.message;
    // seterror(true)
    myAlert.show(message || "Could not fetch upload numbers to set", {
        type: 'error'
    });

  }
}


const loader = (
  <>
      <div style={{ fontSize: 50, lineHeight: 1.1, marginTop: 30 }}>
          <Skeleton count={1} />
      </div>
      <div style={{ fontSize: 350, lineHeight: 1.1, marginTop: 30 }}>
          <Skeleton count={1} />
      </div>
  </>
);

const changeonemodal =    <MyModal maxWidth={800} open={changingnumberone} handleClose={()=>setchangingnumberone(false)}>
  <div className={classes.changeonemodal}>

    <h2>Are you sure you want to change {selectedPhone.number}?</h2>

    <Button style={{ background: "black", color: "white" }}
          variant="primary"
          onClick={() => changeOne()}


 >Change Number</Button>

  </div>

</MyModal>
const pauseonemodal =    <MyModal maxWidth={800} open={pausenumberone} handleClose={()=>setpausenumberone(false)}>
  <div className={classes.changeonemodal}>

    <h2>Are you sure you want to pause {selectedPhone.number}?</h2>

    <Button style={{ background: "black", color: "white" }}
          variant="primary"
          onClick={() => pauseOne()}


 >Pause Number</Button>

  </div>

</MyModal>
const changeallmodal =    <MyModal maxWidth={800} open={changingnumberall} handleClose={()=>setchangingnumberall(false)}>
  <div className={classes.changeonemodal}>

    <h2>Are you sure you want to change all numbers?</h2>
    <h2>This will 100% change every single number that can be changed</h2>
    <p className={classes.selecttext}>Select set of numbers you wish to change</p>
    <p style={{color: "red"}}>PLEASE ENSURE YOU ARE CHANGING THE RIGHT SET!</p>
    <div className={classes.Select1}>

    <select onChange={(e)=> setsettochange(e.target.value)} value={settochange}>
        <option value="">All</option>
        {/* <option value="set1">Set 1</option>
        <option value="set2">Set 2</option> */}

        {
          setgroup && setgroup.map(setitem=> <option key={setitem._id} value={setitem.friendlyName}>{setitem.friendlyNameB}</option>)
        }
    </select>
    </div>

    <Button style={{ background: "red", color: "white" }}
          variant="primary"
          onClick={() => changeAll()}


 >Change ALL Numbers</Button>

  </div>

</MyModal>
const changefailmodal =    <MyModal maxWidth={800} open={changingnumberfail} handleClose={()=>setchangingnumberfail(false)}>
  <div className={classes.changeonemodal}>

    <h2>Are you sure you want to change all numbers that failed in changing?</h2>
    <p style={{color: "red"}}>PLEASE ENSURE YOU ARE CHANGING THE RIGHT SET!</p>
    <p className={classes.selecttext}>Select set of numbers you wish to change</p>
 
    <div className={classes.Select1}>

    <select onChange={(e)=> setsettochange(e.target.value)} value={settochange}>
        {/* <option value="">All</option>
        <option value="set1">Set 1</option>
        <option value="set2">Set 2</option> */}
        <option value="">All</option>
          {
          setgroup && setgroup.map(setitem=> (
            <option value={setitem.friendlyName}>
              {setitem.friendlyNameB}
            </option>
          )
            
            )
        }
    </select>
    </div>

    <Button style={{ background: "black", color: "white" }}
          variant="primary"
          onClick={() => changeFailed()}


 >Change FAILED Numbers</Button>

  </div>

</MyModal>
const changereadymodal =    <MyModal maxWidth={800} open={changereadymodalshow} handleClose={()=>setchangereadymodalshow(false)}>
  <div className={classes.changeonemodal}>

    <h2>Are you sure you want to change all numbers that are in ready status</h2>
    <p style={{color: "red"}}>PLEASE ENSURE YOU ARE CHANGING THE RIGHT SET!</p>
    <p className={classes.selecttext}>Select set of numbers you wish to change</p>
 
    <div className={classes.Select1}>

    <select onChange={(e)=> setsettochange(e.target.value)} value={settochange}>
        {/* <option value="">All</option>
        <option value="set1">Set 1</option>
        <option value="set2">Set 2</option> */}
        <option value="">All</option>
          {
          setgroup && setgroup.map(setitem=> (
            <option value={setitem.friendlyName}>
              {setitem.friendlyNameB}
            </option>
          )
            
            )
        }
    </select>
    </div>

    <Button style={{ background: "green", color: "white" }}
          variant="primary"
          onClick={() => changeReady()}


 >Change READY Numbers</Button>

  </div>

</MyModal>
const targetedpausemodal =    <MyModal maxWidth={800} open={targetedpausemodalshow} handleClose={()=>settargetedpausemodalshow(false)}>
  <div className={classes.changeonemodal}>

    <h2>Targeted Pause Of Numbers!</h2>
    <p style={{color: "red"}}>PLEASE ENSURE YOU ARE PAUSING THE RIGHT SET AND STATUS!</p>
    <p className={classes.selecttext}>Select set of numbers you wish to pause</p>
 
    <div className={classes.Select1}>

    <select onChange={(e)=> setsettochange(e.target.value)} value={settochange}>
        {/* <option value="">All</option>
        <option value="set1">Set 1</option>
        <option value="set2">Set 2</option> */}
        <option value="">All</option>
          {
          setgroup && setgroup.map(setitem=> (
            <option value={setitem.friendlyName}>
              {setitem.friendlyNameB}
            </option>
          )
            
            )
        }
    </select>
    </div>


    <div className={classes.Select1}>

<select onChange={(e)=> setstatustosync(e.target.value)} value={statustosync}>
<option value="">-- Select Status To Pause --</option>
    <option value="failure">FAILURE</option>
    <option value="ready">READY</option>
    <option value="changing">Changing Only</option>
    <option value="changing_executed">Changing Executed</option>
    <option value="changed">CHANGED</option>
    {/* <option value="failure">FAILURE</option> */}
  </select>
  </div>

    <Button style={{ background: "orange", color: "black" }}
          variant="primary"
          onClick={() => pauseTarget()}


 >Pause Target</Button>

  </div>

</MyModal>
const changestuckmodal =    <MyModal maxWidth={800} open={rechangestuck} handleClose={()=>setrechangestuck(false)}>
  <div className={classes.changeonemodal}>

    <h2>Are you sure you want to rechange change all stuck numbers?</h2>
    <p style={{color: "red"}}>PLEASE BE 100% SURE THAT THESE NUMBERS ARE STUCK BEFORE YOU HIT RECHANGE</p>


    <Button style={{ background: "indigo", color: "white" }}
          variant="primary"
          onClick={() => changeStuck()}


 >Change Stuck Numbers</Button>

  </div>

</MyModal>
const resyncmodal =    <MyModal maxWidth={800} open={resync} handleClose={()=>setresync(false)}>
  <div className={classes.changeonemodal}>

    <h2>Are you sure you want to Sync the numbers in the selected set?</h2>
    <p>Ensure you are syncing the right set</p>
    <div className={classes.Select1}>

    <select onChange={(e)=> setsettochange(e.target.value)} value={settochange}>
    <option value="">All</option>
          {
          setgroup && setgroup.map(setitem=> (
            <option value={setitem.friendlyName}>
              {setitem.friendlyNameB}
            </option>
          )
            
            )
        }
      </select>
      </div>
    <div className={classes.Select1}>

    <select onChange={(e)=> setstatustosync(e.target.value)} value={statustosync}>
    <option value="">-- Select Status To Sync --</option>
        <option value="failure">FAILURE</option>
        <option value="ready">READY</option>
        <option value="changing">Changing Only</option>
        <option value="changing_executed">Changing Executed</option>
        <option value="changed">CHANGED</option>
        {/* <option value="failure">FAILURE</option> */}
      </select>
      </div>


    <Button style={{ background: "orange", color: "black", fontWeight: "bolder" }}
          variant="primary"
          onClick={() => resyncNumbers()}


 >Resync Now Baby!</Button>

  </div>

</MyModal>
const actionsModal =    <MyModal maxWidth={800} open={actionsShow} handleClose={()=>setActionsShow(false)}>
  <div className={classes.changeonemodal}>

  <div className={classes.actions1}>
                    
                    <p style={{color: "teal", cursor: "pointer"}}
                                        onClick={bringModalNumberchangeAll}
                    >Change All <FontAwesomeIcon icon={faBatteryFull} /> </p>
                    <p style={{color: "red", cursor: "pointer"}}
                                        onClick={bringModalNumberchangeFail}

                    >Change Failed <FontAwesomeIcon icon={faBatteryFull} /> </p>
                    <p style={{color: "green", cursor: "pointer"}}
                                        onClick={bringModalNumberchangeReady}

                    >Change Ready <FontAwesomeIcon icon={faBatteryFull} /> </p>
                    <p style={{color: "indigo", cursor: "pointer"}}
                                        onClick={bringModalRechangeStuck}

                    >Rechange Stuck <FontAwesomeIcon icon={faStopCircle} /> </p>
                    <p style={{color: "orange", cursor: "pointer"}}
                                        onClick={bringModalSync}

                    >Resync <FontAwesomeIcon icon={faCopyright} /> </p>
                    <p style={{color: "red", cursor: "pointer"}} onClick={stopResync}>Stop Syncing <FontAwesomeIcon icon={faStopCircle} /> </p>
                    <p style={{color: "red", cursor: "pointer"}}
                                        onClick={stopchanging}

                    >Stop Number Change <FontAwesomeIcon icon={faStopCircle} /> </p>
                    <p style={{color: "blue", cursor: "pointer"}}
                                        onClick={bringModalPauseTarget}

                    >Targeted Pause <FontAwesomeIcon icon={faPause} /> </p>
                    
          </div>
          <br/>
          <div className={classes.actions1}>

{
  setgroup && setgroup.map(setitem=> <React.Fragment>
<p onClick={()=> pauseAll(setitem.friendlyName)} style={{fontWeight: 200, cursor: "pointer", color: "red"}}>Pause {setitem.friendlyNameB}</p>
<p onClick={()=> resumeall(setitem.friendlyName)} style={{fontWeight: 200, cursor: "pointer", color: "darkblue"}}>Resume {setitem.friendlyNameB}</p>
    
  </React.Fragment>)
}



</div>

  </div>

</MyModal>

  return (
    <Layout>
      {actionsModal}
      {changeonemodal}
      {resyncmodal}
      {changeallmodal}
      {changereadymodal}
      {changefailmodal}
      {pauseonemodal}
      {changestuckmodal}
      {targetedpausemodal}
      <PromptModal action={addNewSet} prompt={"Are you sure you want to add a new set?"} open={opensetmodal} handleClose={()=>setopensetmodal(false)}/>
   

      <UploadNumbersFile
          open={numbersuploadmodal}

          handleClose={()=> setnumbersuploadmodal(false)}

          action={uploadNumberFile}
          setgroup={setgroup}
          prompt={"Upload Phone Number CSV (uccid,machine,port)"}

      />


      {/* {failureitemsmodal} */}
      <div  className={classes.EnterpriseNumberChange}>
      <div className={classes.Title}><h2 style={{textAlign: "left", width:"100%"}}>Enterprise SIMS</h2>  
      <div className={classes.actions1}>

      <p style={{color: "orange", cursor: "pointer"}}
                                        onClick={()=>fetchNumbers()}
                    >Reload page <FontAwesomeIcon icon={faRecycle} /> </p>
      </div>


      <div className={classes.createnewset}>
        <button  onClick={()=> setActionsShow(true)}>
          Show All Actions
        </button>
        <button  onClick={()=> setopensetmodal(true)}>
          Add Set
        </button>
        <button  onClick={()=> setnumbersuploadmodal(true)}>
          Upload Number List
        </button>
        
{/* 
        <button>
          View Set List
        </button> */}
      </div>
      



        
       </div>

       {status && <h2 style={{fontWeight: 200, fontSize: 20, textAlign: "left"}}>Showing {status}</h2>}
       <div className={classes.actions1}>
            <p  onClick={()=> {
              // fetchNumbers(0, "")
              setstatus("")
            }}   style={{color: "black", fontWeight: 200,cursor: "pointer"}}>All sims : {total}</p>
            <p  onClick={()=> {
              setstatus("failure")
            }} style={{color: "red", fontWeight: 200, cursor: "pointer"}}>Failed sims : {fail}</p>
            <p  onClick={()=> {
              setstatus("changing_executed")
            }}  style={{color: "orange", fontWeight: 200,cursor: "pointer"}}>changing executed sims : {changing}</p>
            <p  onClick={()=> {
              setstatus("changing")
            }}  style={{color: "indigo", fontWeight: 200,cursor: "pointer"}}>changing sims : {changingonly}</p>
            <p  onClick={()=> {
              setstatus("ready")
            }}  style={{color: "purple", fontWeight: 200,cursor: "pointer"}}>ready sims : {ready}</p>
            <p  onClick={()=> {
              setstatus("syncing")
            }}  style={{color: "darkblue", fontWeight: 200,cursor: "pointer"}}>syncing sims : {syncing}</p>
            <p  onClick={()=> {
              setstatus("changed")
            }}  style={{color: "blue", fontWeight: 200,cursor: "pointer"}}>changed sims : {changed}</p>
            <p  onClick={()=> {
              setstatus("paused")
            }}  style={{color: "brown", fontWeight: 200,cursor: "pointer"}}>paused sims : {paused}</p>
          </div>

   
       <div className={classes.Input}>
          <Input placeholder='ENTER THE AREA CODE OF THE CHANGE' value={areacode} onChange={(e)=> setareacode(e.target.value)} fullWidth type='text' />
       </div>
      <br/>
       <div className={classes.Input}>
          <Input placeholder='Search by sim number and phone number' value={search} onChange={(e)=> {
            
            setsearch(e.target.value)

          
          
          }} fullWidth type='text' />
       </div>

          <br></br>
       <div className={classes.Selects}>
        <select value={machine} onChange={(e)=>{
        
          setmachine(e.target.value)}}>
          <option value="">--ALL MACHINES --</option>
          <option value="0">Machine 0</option>
          {/* <option value="2">Machine 2</option> */}
          {Array.from({ length: 40 }, (_, index) => index + 1).map((num, i)=> <option value={num }>Machine {num}</option>)}
        </select>
        <select value={status} onChange={(e)=>{
        
        setstatus(e.target.value)}}>
        <option value="">-- ALL STATUSES --</option>
        <option value="changing">CHANGING</option>
        <option value="changing_executed">CHANGING EXECUTED</option>
        <option value="failure">FAILURE</option>
        <option value="changed">CHANGED</option>
        <option value="paused">PAUSED</option>    
        <option value="ready">READY</option>    
      </select>
        <select value={set} onChange={
          (e)=>{       
            setset(e.target.value)
          }    
        }>
        <option value="">-- ALL SETS --</option>
        {/* <option value="set1">Set 1</option>
        <option value="set2">Set 2</option>   */}

        {
          setgroup && setgroup.map(setitem=> (
            <option value={setitem.friendlyName}>
              {setitem.friendlyNameB}
            </option>
          )
            
            )
        }
      </select>
       </div>
       <br></br>
       <div className={classes.Selects}>
        
       </div>
        <br></br>
      
                {
                    loading && loader
                }

          
                {/* {
                    error && <p style={{ textAlign: "center", fontWeight: 200 }}>Oops. We could not find your enterprise sim numbers please hit refresh</p>
                } */}


               
                { !loading && <div className={classes.Scrollable}>
                  {numbers.length <= 0 && <p>No numbers matching your query was found</p>}
              <SimList

              // phones={phones}
              bringModalNumberpause={bringModalNumberpause}
              numbersindividual={numbersindividual}
              bringModalNumberchange={bringModalNumberchange}
              numbers={numbers}
              loadingnext = {loadingnext}
              loadNext = {()=> {
                setloadingnext(true);
                fetchNumbers(page+ 1)
                
                }}
              />
                </div>}

                    

        
      </div>
    </Layout>
  )
}
