import React, { createContext, useState, useCallback, useRef } from "react";


import delay from '../helperFunctions/delay';
import getAnimationSettings from '../helperFunctions/getAnimationSettings';
// import axios from "axios";
// import  GLOBAL from "../containers/GLOBAL/GLOBAL";
export const NumberChangeContext = createContext();

function NumberChangeContextProvider(props) {
  const [balancechart, setbalancechart] = useState(0);



  	// REFS
	const refAnimationInstance = useRef(null);
	const [intervalId, setIntervalId] = useState(null);

	// CALL BACKS

	const getInstance = useCallback((instance) => {
		refAnimationInstance.current = instance;
	  }, []);
	


	  const nextTickAnimation = useCallback(() => {
		if (refAnimationInstance.current) {
		  refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
		  refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
		}
	  }, []);
	
	  const startAnimation = useCallback(() => {
		if (!intervalId) {
		  setIntervalId(setInterval(nextTickAnimation, 400));
		}
	  }, [intervalId, nextTickAnimation]);
	
	  const pauseAnimation = useCallback(() => {
		clearInterval(intervalId);
		setIntervalId(null);
	  }, [intervalId]);
	
	  const stopAnimation = useCallback(() => {
		clearInterval(intervalId);
		setIntervalId(null);
		refAnimationInstance.current && refAnimationInstance.current.reset();
	  }, [intervalId]);
	
  //    SET ERROR TO NULL

  return (
    <NumberChangeContext.Provider
      value={{
        balancechart,
        setbalancechart,
        getInstance, 
        startAnimation,
        stopAnimation,
        delay,
        pauseAnimation
      }}
    >
      {props.children}
    </NumberChangeContext.Provider>
  );
}

export default NumberChangeContextProvider;
