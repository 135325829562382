import React from "react";
import classes from "./ConversationCreator.module.css";
import { FontAwesomeIcon as F } from "@fortawesome/react-fontawesome";

import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
function ConversationStructure({
  conversationSchema,
  editQuestion,
  editResponse,
  addMessageResponsePair,
  deleteMessageResponsePair,
}) {
  return (
    <ul className={classes.ConversationStructure}>
      {conversationSchema.responseOptions &&
        conversationSchema.responseOptions.map((schema) => {
          return (
            <li key={schema.id}>
              <div className={classes.extension}></div>

              <div className={classes.responseCont}>
                <p
                  onClick={() =>
                    editResponse(
                      schema.id,
                      schema.response,
                      schema.responseToSave
                    )
                  }
                  className={classes.ResponseOption}
                >
                  {/* Lead Response: */}
                  <span>{schema.response}</span>
                </p>
              </div>

              <div className={classes.questionCont}>
                <p
                  onClick={() => editQuestion(schema.id, schema.question)}
                  className={classes.NextMessage}
                >
                  {/* Next Message :  */}
                  <span>
                    {schema.question ? (
                      schema.question
                    ) : (
                      <span style={{ opacity: 0.3 }}>
                        Enter a follow up message
                      </span>
                    )}
                  </span>
                </p>
                <div className={classes.AddResponseOption}>
                  {schema.question ? (
                    <F
                      icon={faPlusCircle}
                      onClick={() => addMessageResponsePair(schema.id)}
                    />
                  ) : null}

                  <F
                    icon={faTrash}
                    onClick={() => deleteMessageResponsePair(schema.id)}
                  />
                </div>
              </div>
              <ConversationStructure
                conversationSchema={schema}
                editQuestion={editQuestion}
                editResponse={editResponse}
                addMessageResponsePair={addMessageResponsePair}
                deleteMessageResponsePair={deleteMessageResponsePair}
              />
            </li>
          );
        })}
    </ul>
  );
}

export default ConversationStructure;
