import React from 'react';
import classes from "./EnterpriseNumberChange.module.css"
import _2digits from "../../helperFunctions/_2digts";
import moment from 'moment'
import { LinearProgress } from '@material-ui/core';

const backgroundFormatter = (status)=>{
    
    if( status === "syncing"){
        return "indigo"
    }
    if( status === "changed"){
        return "rgb(90, 23, 220)"
    }
    if(status === "ready" ){
        return "rgb(80,132,89)"
    }
    if(status === "paused" ){
        return "rgb(200,0,200)"
    }
    if(status === "failure" ){
        return "red"
    }
    if(status === "changing" ){
        return "orange"
    }
    if(status === "changing_executeed" ){
        return "blue"
    }


    return "black"
}
const SimList = ({
    numbers,
    // changeOne,
    bringModalNumberchange,
    bringModalNumberpause,
    numbersindividual,
    loadNext,
    loadingnext
}) => {
    
    return (
        <ul className={classes.SimList}>
            {numbers && numbers.map((c, i) => {
                return <> <li>
                    <div className={classes.Flex}>
                        <strong style={{marginRight: 10}}>{c?.number}</strong> 
                      


                        <p>  
                         
                            <span className={classes.status} style={{
                        background: backgroundFormatter(c?.resync? "syncing": c?.status)
                    }}>{ c?.resync? "Syncing": c?.status?.toUpperCase()} </span></p>
                        </div>
                        <p style={{marginRight: 10}}>Set: {c?.set?.replace("set", "")}</p>
                        <p style={{marginRight: 10}}>SIM Number: {c?.msninumber}</p>
                        <p style={{marginRight: 10}}>Machine: {c?.machineNumber}</p>
                      
                        <p style={{marginRight: 10}}>Port: {c?.portnumber}</p>
                        {c?.status === "failure" && <p style={{marginRight: 10}}>Error: {c?.errorText || "To be determined"}</p>}
                    <div className={classes.Row}>

                
                    {/* <p className={classes.Soft}>last change date: {dateString}</p> */}


                    <div className={classes.actions}>
                        <div onClick={()=>bringModalNumberchange(c)} style={{marginRight: 10}} className={classes.changenumber}>Change Number</div>
                        <div onClick={()=>bringModalNumberpause(c)} style={{marginRight: 10, 
                        
                        background: c.status === "paused"? "green": "red"
                        
                        }} className={classes.pausenumber}>{c.status !=="paused"? "Pause Number": "Resume Number"}</div>


                 
                    </div>


              
                    <p style={{fontWeight: 200}}>   changed {moment(c.timeoflastchange).fromNow()} </p>
                    </div>

                    <p className={classes.prevchanges}>Previous changes</p>
                    <br></br>
                    <div className={classes.changes}>
                        {numbersindividual[i] && numbersindividual[i].map((ne,j)=>{
                            // let reqitem = numbersindividual[i][j]
                            let nextitem = numbersindividual[i][j+1]

                            if(nextitem){
                                return <div className={classes.changeColumn} key={j}>
                                <p><strong>{ne.number }</strong> </p>
                                <p>{moment(nextitem.changeDate).fromNow()}</p>
                                </div>
                            }

                            return <div  className={classes.changeColumn} key={j}>
                            <p>{ne.number }  </p>

                            <p>N/A</p>
                            </div>


                           
                        
                        })}
                    </div>

                   
                </li>
                {numbers.length -1 === i  ? <p   onClick={loadNext} style={{textAlign: "center", color: "blueviolet"}}>See more</p>: null}
                    {numbers.length -1 === i && loadingnext && <LinearProgress/>}
                </>
                
            })}
        </ul>
    );
}

export default SimList;
