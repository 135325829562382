import React, { createContext, useState } from "react";
import { useAlert } from 'react-alert'


// import axios from "axios";
import RequestModule from "../helperFunctions/REQ"
import GLOBAL from "../containers/GLOBAL/GLOBAL";
export const CreateSubIDContext = createContext();

function CreateSubIDContextProvider(props) {
    const [trafficList, setTrafficList] = useState([]);
    const [forwardings, setforwardings] = useState([]);
    const [scrubrule,setscrubrule] = useState("");
    const [dataOwnerList, setDataOwnerList] = useState([]);
    const [subs, setsubs] = useState([]);
    const [defaultforwarding, setdefaultforwarding] = useState({});
    const [forwardingloading, setforwardingloading] = useState([]);

    const myAlert = useAlert()


    const fetchSubIDs = async () => {
        //`${Global.domainClickGet}/api/getallsubs-23`

        try {
            const subs = await RequestModule('GET', `${GLOBAL.domainClickGet}/api/getallsubs-23`)
            console.log(subs, "subs")
            setsubs(subs.data.reverse())
            // myAlert.show('Sub ids available', {
            //     type: 'success'
            // });

        } catch (error) {

            myAlert.show('Could not fetch sub ids', {
                type: 'error'
            });
        }
    }




    const createSubID = async (subid, dataowner, traffic) => {
        try {
            const response = await RequestModule('POST', `${GLOBAL.domainClickGet}/api/subid/create`, {
                subid, dataowner, traffic
            }, true)
            console.log(response);
            myAlert.show('Successfully Created Sub ID', {
                type: 'success'
            });
            fetchForwardings();
            return true;
        } catch (error) {
            console.log(error)
            const msg = error?.response?.data?.message;
            myAlert.show(msg || 'Could not fetch traffic', {
                type: 'error'
            });

            return false;
        }
    }
    const getdefaultforwarding = async()=>{
        try {

            // setforwardingloading(true)
            const flist = await RequestModule('GET', `${GLOBAL.domainNameCheap}/api/get_default_forwarding`)
            console.log(flist, 24)

            setdefaultforwarding(flist.data)
            // myAlert.show('Traffic available', {
            //     type: 'success'
            // });

        } catch (error) {

            myAlert.show('Could not fetch forwardings', {
                type: 'error'
            });
            // return false;
        } finally{
            setforwardingloading(false)
        }
    }
    const createForward = async (subid, urls, name, forwardinglink)=>{
        try {

            let actualSub = subs.find(s=> s.subIDFull === subid);
            if(!actualSub) {
                console.log(actualSub)
                return myAlert.show("Sub not found",  {
                    type: 'failure'
                })
            }
            const response = await RequestModule('POST', `${GLOBAL.domainClickGet}/api/forwardnow`, {
                subid: actualSub,
                name: name,
                urls: urls.split("\n"),
                forwardinglink

            }, true)
            console.log(response);
            myAlert.show('Successfully Initiated Forwarding request', {
                type: 'success'
            });
            return true;
        } catch (error) {
            console.log(error)
            const msg = error?.response?.data?.message;
            myAlert.show(msg || 'Could not fetch traffic', {
                type: 'error'
            });

            return false;
        }
    }
    const fetchForwardings = async () => {
        try {

            setforwardingloading(true)
            const flist = await RequestModule('GET', `${GLOBAL.domainClickGet}/api/getforwardings`)
            console.log(flist, 24)

            setforwardings(flist.data)
            // myAlert.show('Traffic available', {
            //     type: 'success'
            // });

        } catch (error) {

            myAlert.show('Could not fetch forwardings', {
                type: 'error'
            });
            // return false;
        } finally{
            setforwardingloading(false)
        }
    }
    const fetchTraffic = async () => {
        try {
            const trafficList = await RequestModule('GET', `${GLOBAL.domainNameCheap}/api/gettraffic`)
            // console.log(trafficList, 24)
            setTrafficList(trafficList.data)
            // myAlert.show('Traffic available', {
            //     type: 'success'
            // });

        } catch (error) {

            myAlert.show('Could not fetch traffic', {
                type: 'error'
            });
        }
    }
    const fetchDataowner = async () => {
        try {
            const dataownerList = await RequestModule('GET', `${GLOBAL.domainDataowner}/api/get-profit-sharers`, null, true)
            // console.log(dataownerList, 24)
            setDataOwnerList(dataownerList.data)
            // myAlert.show('Data owners available', {
            //     type: 'success'
            // });

        } catch (error) {
            console.log(error)
            myAlert.show('Could not fetch dataowner', {
                type: 'error'
            });
        }
    }
    const fetchCurrentProfitSharerScrubRule = async (profitsharer) => {
        try {
            const {data} = await RequestModule('GET', `${GLOBAL.domainDataowner}/api/profitsharescrubrule?profitsharer=${profitsharer}`, null, true)

            setscrubrule(data)

            // console.log(dataownerList, 24)
            // setDataOwnerList(dataownerList.data)
            // myAlert.show('Data owners available', {
            //     type: 'success'
            // });

        } catch (error) {
            console.log(error)
            myAlert.show('Could not fetch dataowner', {
                type: 'error'
            });
        }
    }
    const setScrubRule = async (profitsharer, percentage) => {
        try {
            const {data} = await RequestModule('GET', `${GLOBAL.domainDataowner}/api/saveprofitsharescrubrule?profitsharer=${profitsharer}&percentage=${percentage}`, null, true);

            // setscrubrule(data)

            console.log(data)

            // fetchCurrentProfitSharerScrubRule()

            // console.log(dataownerList, 24)
            // setDataOwnerList(dataownerList.data)
            // myAlert.show('Data owners available', {
            //     type: 'success'
            // });

        } catch (error) {
            console.log(error)
            myAlert.show('Could not fetch dataowner', {
                type: 'error'
            });
        }
    }




    //    SET ERROR TO NULL

    return (
        <CreateSubIDContext.Provider
            value={{
                trafficList,
                fetchTraffic,
                fetchSubIDs,
                subs,
                scrubrule,
                dataOwnerList,
                fetchDataowner,
                createSubID,
                fetchCurrentProfitSharerScrubRule,
                setScrubRule,
                createForward,
                fetchForwardings,
                forwardings,
                forwardingloading,
                defaultforwarding,
                getdefaultforwarding,
            }}
        >
            {props.children}
        </CreateSubIDContext.Provider>
    );
}

export default CreateSubIDContextProvider;
