export const AUTH_START = 'AUTH_START';
export const AUTH_CHECK_START = 'AUTH_CHECK_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const ADD_TO_CART = 'ADD_TO_CART';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SHOW_AUTH_MODAL = 'SHOW_AUTH_MODAL';
export const SET_AUTH_MODAL_TO_TRUE = 'SET_AUTH_MODAL_TO_TRUE';
export const AUTH_CHECK_BEFORE_AUTH = 'AUTH_CHECK_BEFORE_AUTH';
export const TELL_USER_TO_VERIFY = 'TELL_USER_TO_VERIFY';
export const SET_REDIRECT_TO_EMAIL_VERIFICATION_PAGE =
  'SET_REDIRECT_TO_EMAIL_VERIFICATION_PAGE';
