import React from 'react'
import classes from "./EnterpriseNumberChange.module.css"
import MyModal from "../../components/MyModal/MyModal";
import { Button } from '@material-ui/core';

function PromptModal({
    open,
    handleClose,
    prompt,
    action
}) {
  return (
    <MyModal maxWidth={800} open={open} handleClose={handleClose}>
  <div className={classes.changeonemodal}>

       <h2> {prompt}</h2>


    <Button style={{ background: "black", color: "white" }}
          variant="primary"
          onClick={action}


 >Create </Button>

  </div>

</MyModal>
  )
}

export default PromptModal
