import React, { useRef, useState } from 'react'
import classes from "./EnterpriseNumberChange.module.css"
import MyModal from "../../components/MyModal/MyModal";
import { Button, LinearProgress } from '@material-ui/core';

function UploadNumbersFile({
    open,
    handleClose,
    prompt,
    action,
    setgroup
}) {

    const [set, setset] = useState("")
    const [loading, setloading] = useState(false);
    const [file, setfile] = useState(null)

    const fileInputRef = useRef(null);


    // const handleFileUpload = () => {
    //     // Trigger click event on input element
    //     fileInputRef.current.click();
    //   };


      const handleFileChange = async (event) => {
        const file = event.target.files[0];
        // const formData = new FormData();
        // formData.append('file', file);

        setfile(file);
    
        // try {
        //   // Send file data to backend using fetch or Axios
        //   const response = await fetch('your-backend-url', {
        //     method: 'POST',
        //     body: formData
        //   });
        //   // Handle response from backend
        // } catch (error) {
        //   // Handle error
        // }
      };
  return (
    <MyModal maxWidth={800} open={open} handleClose={handleClose}>
  <div className={classes.changeonemodal}>
    {loading && <LinearProgress/>}

       <h2> {prompt}</h2>




<div className="fileupload">
    <input type="file"  onChange={handleFileChange} />
</div>

<br/>
<div className={classes.Select1}>

<select onChange={(e)=> setset(e.target.value)} value={set}>
    {/* <option value="set1">Set 1</option>
    <option value="set2">Set 2</option> */}

    <option value="">-- Select Set --</option>

    {
      setgroup && setgroup.map(setitem=> <option key={setitem._id} value={setitem.friendlyName}>{setitem.friendlyNameB}</option>)
    }
</select>
</div>

<br/>

<Button style={{ background: "black", color: "white" }}
          variant="primary"
          onClick={ async ()=>{
            setloading(true)
                try {
                  await action(set, file)
                } catch (error) {
                    alert("Could not upload");
                } finally{
                    setloading(false);
                }
          }}


 >Upload </Button>

  </div>

</MyModal>
  )
}

export default UploadNumbersFile
